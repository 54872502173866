import Rails from '@rails/ujs'
Rails.start()

import '@hotwired/turbo-rails'
import '~/modules/bulma/index.scss'

import '~/modules/shoelace'

// Controller Roots
import '~/controllers'
